<template>
  <div class="acceptInvitationWithToken">
    <LSection>
      <template #body>
        <TokenStatus
          operation="ACCEPT_INVITATION"
          :token="acceptInvitationToken"
          tokenName="acceptInvitationToken"
          @token-is-valid="() => (tokenIsValid = true)"
          @token-is-invalid="() => (tokenIsValid = false)"
          @token-has-expired="() => (tokenIsValid = false)"
        ></TokenStatus>
        <div v-if="!tokenIsValid" class="flex justify-center">
          <form @submit.prevent="onSubmit" ref="form" class="form">
            <h1 class="heading1">
              Congratulations
              <hr class="gradientLine" />
            </h1>

            <p class="text-sm k15:text-lg text-gray-600">
              You have been invited to ENTER organisationName. Please create an
              account to accept the invitation.
            </p>
            <div>
              <label for="name">Full name</label>
              <FullName @change="onNameChange"></FullName>
              <LFieldError :message="inputError.name"></LFieldError>
            </div>
            <div>
              <label for="role">Role in company</label>
              <TextInput
                :tabIndex="3"
                id="role"
                name="role"
                type="role"
                placeholder="role"
                @change="onRoleChange"
              ></TextInput>
            </div>
            <div>
              <label for="password">Password</label>
              <TextInput
                :tabIndex="4"
                id="password"
                name="password"
                type="password"
                placeholder="Your passphrase"
                @change="onPasswordChange"
              ></TextInput>
              <LFieldError :message="inputError.password"></LFieldError>
            </div>
            <div>
              <label for="passwordRepeat">Confirm Password</label>
              <TextInput
                :tabindex="5"
                id="passwordRepeat"
                name="passwordRepeat"
                type="password"
                placeholder="Repeat your passphrase"
                @input="onPasswordRepeatChange"
                @change="onPasswordRepeatChange"
              >
              </TextInput>
              <LFieldError :message="inputError.passwordRepeat"></LFieldError>
            </div>
            <div @click="() => onAcceptTermsChange(!userAccount.acceptTerms)">
              <input
                class="mr-1"
                type="checkbox"
                :tabindex="5"
                :checked="userAccount.acceptTerms"
              />
              <label>Accept terms and conditions</label>
            </div>
            <div
              class="w-full"
              @click="
                () =>
                  onAcceptPrivacyPolicyChange(!userAccount.acceptPrivacyPolicy)
              "
            >
              <input
                class="mr-1"
                type="checkbox"
                :tabindex="6"
                :checked="userAccount.acceptPrivacyPolicy"
              />
              <label>Accept privacy policy</label>
            </div>
            <div class="items-center justify-end gap-4 flex flex-row">
              <Button
                class="button secondary"
                @click="$router.go(-1)"
                :tabindex="7"
                type="submit"
              >
                <template #right>Back</template>
              </Button>
              <Button
                class="button primary"
                type="submit"
                :tabindex="8"
                @click="onSubmit"
              >
                <template #right>Register</template>
              </Button>
            </div>
          </form>
        </div>
      </template>
    </LSection>
  </div>
</template>

<script>
import TextInput from "@/components/forms/TextInput.vue";
import { computed, reactive, ref, watch, watchPostEffect } from "vue";
import LSection from "@/components/layout/LSection.vue";
import FullName from "@/components/forms/FullName.vue";
import Button from "@/components/forms/Button";
import { usePassphrase } from "@/lib/usePassphrase";
import {
  isStringWithLength,
  isStringWithMinLength,
} from "@/lib/typeHelpers/stringFunctions/isStringWithLength";
import LFieldError from "@/components/layout/LFieldError";
import { useDocuments } from "@/composables/useDocuments";
import TokenStatus from "@/components/authentication/TokenStatus.vue";
import { useStatusMessage } from "@/composables/useStatusMessage";
import { ACCEPT_INVITATION } from "@/store/operations";

export default {
  name: "AcceptInvitationWithToken",
  props: {
    acceptInvitationToken: {
      type: String,
      required: true,
    },
  },
  components: {
    Button,
    FullName,
    LFieldError,
    LSection,
    TextInput,
    TokenStatus,
  },
  setup() {
    const form = ref(null);

    const { hashPassphrase } = usePassphrase();

    const inputError = reactive({});
    const userAccount = reactive({
      name: null,
      role: null,
      password: null,
      passwordRepeat: null,
      acceptTerms: null,
      acceptPrivacyPolicy: null,
    });
    const isValid = computed(
      () =>
        userAccount.name && Object.values(inputError).every((msg) => msg === "")
    );

    const onNameChange = (value) => (userAccount.name = value.trim());
    const onRoleChange = (value) => (userAccount.role = value.trim());
    const onPasswordChange = (value) => {
      if (!isStringWithMinLength(value)) {
        inputError.password =
          value !== null && !isStringWithMinLength(value)
            ? "Minimum length of 6 characters"
            : "";
      } else {
        inputError.password = "";
        userAccount.password = hashPassphrase(value.trim());
      }
    };
    const onPasswordRepeatChange = (value) =>
      (userAccount.passwordRepeat = hashPassphrase(value.trim()));
    const onAcceptTermsChange = (value) => (userAccount.acceptTerms = value);
    const onAcceptPrivacyPolicyChange = (value) =>
      (userAccount.acceptPrivacyPolicy = value);

    watchPostEffect(() => {
      const {
        name,
        role,
        password,
        passwordRepeat,
        acceptTerms,
        acceptPrivacyPolicy,
      } = userAccount;

      inputError.name =
        name !== null && !isStringWithLength(name) ? "Name is required" : "";
      inputError.role = role !== null ? "Please enter a role" : "";
      /*inputError.password =
        password !== null && !isStringWithLength(password)
          ? "A password with a minimum length of 6 is required"
          : "";*/
      inputError.acceptTerms =
        acceptTerms !== null && !acceptTerms
          ? "You must accept our terms and conditions"
          : "";
      inputError.acceptPrivacyPolicy =
        acceptPrivacyPolicy !== null && !acceptPrivacyPolicy
          ? "You must accept our privacy policy"
          : "";
      inputError.passwordRepeat =
        passwordRepeat !== null && !isStringWithLength(passwordRepeat)
          ? "Please repeat your password"
          : "";
      inputError.passwordRepeat =
        !inputError.passwordRepeat &&
        isStringWithLength(passwordRepeat) &&
        passwordRepeat !== null &&
        passwordRepeat !== password
          ? "Your passwords do not match"
          : "";
    });
    const { createDocument } = useDocuments("UserAccount");

    const { setMessage, deleteMessage } = useStatusMessage(ACCEPT_INVITATION);

    const onSubmit = () => {
      console.log("validating userAccount info");

      const options = {
        ...userAccount,
      };
      delete options.passwordRepeat;

      console.log("Requesting new userAccount");
      createDocument(options)
        .then((result) => {
          console.log("UserAccount created");
          setMessage({ isSuccess: true, body: result._message });
        })
        .catch((err) => {
          console.log("Error creating userAccount", err);
          setMessage({ isError: true, body: err.message });
        });
    };

    const tokenIsValid = ref(null);

    deleteMessage();

    watch(tokenIsValid, (value) => {
      if (value === true)
        setMessage({ isSuccess: true, body: "Your token is valid." });
      else if (value === false)
        setMessage({
          isError: true,
          body: "Your token is invalid. It may have been used before or it may have expired. Please try again.",
        });
    });

    return {
      inputError,
      isValid,
      form,
      onSubmit,
      userAccount,
      onRoleChange,
      onNameChange,
      onPasswordChange,
      onPasswordRepeatChange,
      onAcceptTermsChange,
      onAcceptPrivacyPolicyChange,
      tokenIsValid,
    };
  },
};
</script>

<style scoped></style>
