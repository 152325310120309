<template>
  <div class="FullName">
    <div class="w-50 md:w-auto lg:w-1/3">
      <div class="flex">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 bg-gray-200 rounded-full p-1 m-2"
        >
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
          />
        </svg>
        <TextInput
            :tabIndex="1"
            id="fullname"
            name="fullname"
            placeholder="Full name"
            @input="onInput"
            @change="onChange"
        ></TextInput>
      </div>
    </div>
  </div>
</template>
<script>
import TextInput from "@/components/forms/TextInput.vue";
import {useFieldValidation} from "@/composables/useFieldValidation";
import {ref} from "vue";

export default {
  emits: ["change"],
  components: {
    TextInput,
  },
  name: "FullName",
  setup(props, {emit}) {
    const error = ref("");

    const {validate} = useFieldValidation();
    const onInput = (value) => {
      error.value = validate(value);
    };
    const onChange = (value) => emit("change", value);
    return {
      onInput,
      error,
      onChange,
    };
  },
};
</script>
